/*******************************************************************************
  DownloadCumulative.js

  Author: Mark Duckworth; Advait; 

*******************************************************************************/

import React, {useState} from 'react'
import { downloadFile } from '../apihelpers/apihelpers';
import '../index.css'

/**
 * Component to download a cumulative inspection report.
 * Text fields for entering the start and end dates, and a download button.
 * 
 * TODO This could be made more generic and combined with
 * TODO DownloadRoundData and DownloadUsageReports.
 */
function DownloadCumulativeReport(props) {
    const [areDatesValid, setAreDatesValid] = useState(false);

    /**
     * Validate date format using regex (YYYY-MM-DD)
     */
    function isValidDate(date) {
        const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
        return regex.test(date);
    }

    /**
     * Compare start and end dates to ensure start date ≤ end date
     */
    function isStartDateBeforeEndDate(startDate, endDate) {
        return new Date(startDate) <= new Date(endDate);
    }

    /**
     * Handler to check if both dates are entered, valid, and start date ≤ end date
     */
    function handleDateChange() {
        const startDate = document.getElementById("cumStart").value;
        const endDate = document.getElementById("cumEnd").value;

        // Check if both dates are valid
        const isStartDateValid = isValidDate(startDate);
        const isEndDateValid = isValidDate(endDate);

        // Check if start date is before or equal to end date
        const isStartBeforeEnd = isStartDateBeforeEndDate(startDate, endDate);

        // Update state only if both dates are valid and start date ≤ end date
        setAreDatesValid(isStartDateValid && isEndDateValid && isStartBeforeEnd);
    }

    /**
     * Click handler to perform the file download
     * @param {*} event 
     */
    function doDownload(event) {
        event.preventDefault();
        const startDate = document.getElementById("cumStart").value;
        const endDate = document.getElementById("cumEnd").value;
        const apiPath = `/sites/${props.siteid}/inspections/cumulative?start=${startDate}&end=${endDate}`;
  
        // Create a filename for the saved file on the local machine
        let filename = props.sysAdmin ? 
            `cumulative_${props.tenantName}_${props.siteName}_${startDate}_to_${endDate}.csv` :
            `cumulative_${props.siteName}_${startDate}_to_${endDate}.csv`;
        downloadFile(apiPath, filename);
    }

    return (
        <div>
        <span className="individualContainer">
            <h4>Download Cumulative Inspection Report</h4>
            <table className="dateTable">
                <tbody>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="cumStart"
                                type="text"
                                required
                                minLength="10"
                                maxLength="10"
                                placeholder="YYYY-MM-DD"
                                onChange={handleDateChange}
                                className="dateInput"
                            />
                        </td>
                        <td>
                            <input
                                id="cumEnd"
                                type="text"
                                required
                                minLength="10"
                                maxLength="10"
                                placeholder="YYYY-MM-DD"
                                onChange={handleDateChange}
                                className="dateInput"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            {areDatesValid && (
                <input
                    // className="saveButton"
                    type="submit"
                    value="Download"
                    onClick={doDownload}
                />
            )}
        </span>
        <br />
        </div>
    );
}

export default DownloadCumulativeReport;