/*******************************************************************************
  DownloadRounds.js

  Author: Mark Duckworth; Advait; 

*******************************************************************************/

import React from 'react'
import { API } from 'aws-amplify'
import { downloadFile } from '../apihelpers/apihelpers';
import '../index.css'

/**
 * Component to download a usage report. Text fields for entering
 * the start and end dates, and a download button.
 */
function DownloadUsageReport(props) {
    /**
     * Click handler to perform the file download
     * @param {*} event 
     */
    function doDownloadUsage(event) {
        event.preventDefault();
        const startDate = document.getElementById("usageStart").value;
        const endDate = document.getElementById("usageEnd").value;
        const api_path = `/usagereport?start=${startDate}&end=${endDate}`;
  
        // Create a filename for the saved file on the local machine
        let filename = `usage_report_${startDate}_to_${endDate}.csv`;
        downloadFile(api_path, filename);
    }

    /**
     * Handler to check if both dates are entered and toggle the download button visibility
     */
    function handleDateChange() {
        const startDate = document.getElementById("usageStart").value;
        const endDate = document.getElementById("usageEnd").value;
        const downloadButton = document.getElementById("downloadButton");

        // Show the button only if both dates are entered
        if (startDate.length === 10 && endDate.length === 10) {
            downloadButton.style.display = "inline-block";
        } else {
            downloadButton.style.display = "none";
        }
    }

    return (
        <div>
            <h2>Download Usage Report</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Start Date</td>
                        <td>End Date</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="usageStart"
                                type="text"
                                required
                                minLength="10"
                                maxLength="10"
                                placeholder="2024-12-01"
                                onChange={handleDateChange}
                            />
                        </td>
                        <td>
                            <input
                                id="usageEnd"
                                type="text"
                                required
                                minLength="10"
                                maxLength="10"
                                placeholder="2024-12-31"
                                onChange={handleDateChange}
                            />
                        </td>
                        <td>
                            <input
                                id="downloadButton"
                                className="saveButton"
                                type="submit"
                                value="Download"
                                onClick={doDownloadUsage}
                                style={{ display: "none" }} // Initially hidden
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DownloadUsageReport;