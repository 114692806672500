/*******************************************************************************
  SelectSiteSimple.js

  Author: Advait; Mark Duckworth;

*******************************************************************************/

import React, {Component} from 'react'
import { getSites } from '../apihelpers/graphqlhelpers'
import '../index.css'

/**
 * This component allows the user to select a site from the list of sites
 * owned by the tenant. If there is only one site, it selects that site
 * automatically.
 * This component calls the onChange callback (passed in via props)
 * whenever the selected site id changes.
 */
class SelectSiteSimple extends Component{
  constructor(props){
    super(props)
    this.onChange = props.onChange;  // callback when site is selected
    this.state={
      sites:[],                     // list of sites from database
      selectedSite:"",              // id of site the user selected
      numberOfSites:"",
      selectedTenant:"",
      siteName:""
    }
    this.handleSelectedSite = this.handleSelectedSite.bind(this)
  }

  async componentDidMount() {
    this.setState({selectedSite: localStorage.getItem("selectedSite")})
    this.refreshFromDB()
    //this.handleSelectedSite(new Event('select', {selectedSite: localStorage.getItem("selectedSite")}))
    // const id = localStorage.getItem("selectedSite");
    // console.log(id, this.state.selectedSite)
    // if (id) {
    //     console.log("should register change: "+id)
    //     this.onChange(id, this.getSiteInfo(id))
    // }
  }

  componentDidUpdate(prevProps) {
    // Parent can update refreshCount to ask us to refresh our data
    // from the database.
    if (this.props.refreshCount !== prevProps.refreshCount) {
      this.refreshFromDB();
    }
  }

  async refreshFromDB(){
    let sites = await getSites(this.props.selectedTenant, this.props.sysAdmin, false);
    var count = Object.keys(sites).length;
    this.setState({numberOfSites:count})
    if(count === 1){
      this.setState({siteName:sites[0].siteName})
      this.setState({selectedSite:sites[0].id, selectedSiteStatus:null})
      this.setState({sites:sites});
      localStorage.setItem('selectedSite', sites[0].id);
      //this.onChange(sites[0].id, this.getSiteInfo(sites[0].id));
    }else{
      this.setState({sites:sites});
    }
    // check if the siteid from localstorage exists in 
    // the site list for selected tenant
    const restoredSite = sites.find((site) => site.id === localStorage.getItem("selectedSite"));
    const id = restoredSite ? restoredSite.id : '';
    if (id) {
        console.log("refreshFromDB should register change: "+id)
        this.onChange(id, this.getSiteInfo(id))
    } else{
      // clear the state selectedSite if site does not exists for selected tenant
      this.setState({selectedSite:''})
      localStorage.setItem('selectedSite', '');
    }
  }
  
  componentWillUnmount(){
    this.setState({selectedSite:""})
  }

  handleSelectedSite(event){
    const siteid = event.target.value;
    console.log(event);
    this.setState({selectedSite:siteid})
    this.onChange(siteid, this.getSiteInfo(siteid));
  }

  /**
   * Get some field values for the Site
   */
  getSiteInfo(id) {
      let info = {
        siteName: "",
        emailsToNotify: "",
        tid: "",
      };
      if (id) {
        console.log("getting info for: " + id)
        const site = this.state.sites.find((site) => site.id === id);
        if (site) {
          info = {
            siteName: site.siteName,
            emailsToNotify: site.emailsToNotify,
            alarmThresholds: JSON.parse(site.alarmThresholds),
            tid: site.tid,
            uvRestrictHours: site.uvRestrictHours,
            rmsName: site.rmsName,
            rmsUser: site.rmsUser,
            rmsPw: site.rmsPw,
            mapName: site.mapName,
            adminEmailsToNotify: site.adminEmailsToNotify,
            timezone: site.timezone,
            recharge_threshold_min: site.recharge_threshold_min,
            recharge_threshold_max: site.recharge_threshold_max,
            restrict_ui_page:JSON.parse(site.restrict_ui_page),
            securityRound_action:site.securityRound_action,
            securityRound_wait_time:site.securityRound_wait_time,
            include_tags_in_round:JSON.parse(site.include_tags_in_round)
          };
        }
      }
      return info;
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************

  render(){
    if(this.state.numberOfSites === 1){
      return (
        <div>
          <div className="individualContainer">
            <label>Site: {this.state.siteName}</label>
          </div>
          <br/>
        </div>
      )
    }else if(this.state.numberOfSites === 0){
      return (
        <div className="individualContainer">
          <label>No site available</label>
        </div>
      )
    }else{
      const allsites = [].concat(this.state.sites)
      .map((site,i)=>
        {
          return  <option key={i} value={site.id}>
                    {site.siteName}
                  </option>
        }
      )
      return (
        <div>
          <div className="individualContainer">
            <h4>Site Selection</h4>
            <br/>
            <select
              value={this.state.selectedSite}
              onChange={this.handleSelectedSite}
            >
              <option value="">-- Please choose a site --</option>
              {allsites}
            </select>
          </div>
          <br/>
        </div>
      )
    }   
  }
}
export default SelectSiteSimple