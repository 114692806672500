/*******************************************************************************
  SelectSite.js

  Author: Advait

*******************************************************************************/

import React, { Component } from 'react'
import SelectSiteSimple from '../components/SelectSiteSimple'
import SelectTenant from './SelectTenant'
import ScheduleItemTable from '../components/ScheduleItemTable'
import RobotCurrentMode from '../components/RobotCurrentMode'
import AlarmConfigSection from '../components/AlarmConfigSection'
import ShowRounds from '../components/ShowRounds'
import SiteConfigSection from '../components/SiteConfigSection'
import ShowDisinfectionAllowedHrs from '../components/ShowDisinfectionAllowedHrs'
import LocationBehavior from '../components/LocationBehavior'
import IncludeTags from '../components/IncludeTags'
import '../index.css'

class SelectSite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sysAdmin: false,
      selectedSite: "",              // id of site the user selected
      siteName: "",
      selectedTenant: "",
      tenantName: "",
      refreshCount: 0,  // increment to cause the SelectSiteSimple to refresh from database
      emailsToNotify:"",
      adminEmailsToNotify:"",
      allowDisinfectionatAllHrs: true,
      rmsName: "", // e.g. base.ava8.net 
      rmsUser: "",
      rmsPw: "",
      mapName:"",
      timezone: "",
      recharge_threshold_min: "",
      recharge_threshold_max: "",
      restrict_ui_page:"",
      securityRound_action:"NONE",
      securityRound_wait_time:0,
      isLoading: true, // delay loading of element so have a clean loadout/load view
      include_tags_in_round:""
    }
    this.handleSiteChange = this.handleSiteChange.bind(this)
    this.handleThresholdsSaved = this.handleThresholdsSaved.bind(this)
    this.handleTenantChange = this.handleTenantChange.bind(this)
    this.handleSiteConfigChange = this.handleSiteConfigChange.bind(this)
    this.handleuvRestrictHoursChange = this.handleuvRestrictHoursChange.bind(this)
    this.handleLocationBehaviorChange = this.handleLocationBehaviorChange.bind(this)
    this.handleIncludeTagsChange = this.handleIncludeTagsChange.bind(this)
  }
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageType !== prevProps.pageType) {
      // Conditionally clear selectedSite if appropriate for your application
      localStorage.setItem('selectedSite', "");
      this.setState({ selectedSite: "", isLoading: true }, () => {
        this.loadData(); // Reload data after clearing
      });
    }
  }
  //***************************************************************************
  // called when either component mounts or pagetype is changed 
  //***************************************************************************
  loadData() {
    // Load data from localStorage and set state
    const sysAdmin = localStorage.getItem('sysadmin') === "true";
    const selectedTenant = localStorage.getItem('selectedTenant');
    const selectedSite = localStorage.getItem('selectedSite');
    const alarmThresholds = localStorage.getItem('alarmThresholds');
    let thresholdsFromDb = null;

    if (alarmThresholds) {
      try {
        thresholdsFromDb = JSON.parse(alarmThresholds);
      } catch (error) {
        console.error("Error parsing alarmThresholds from localStorage:", error);
        thresholdsFromDb = null; // Handle parse error appropriately
      }
    }
    this.setState({
      sysAdmin: sysAdmin,
      selectedTenant: selectedTenant,
      selectedSite: selectedSite,
      thresholdsFromDb: thresholdsFromDb,
      // isLoading: false //  crucial: Set isLoading to false *after* loading data
    });
    setTimeout(() => {
    /***************************************************************************
     * Issue observed by Mark earlier is now taken care by isLoading state
     * "When I go to a different page, the site selection keeps the site I selected before. 
     *  But everything else on the page flashes briefly then disappears. 
     *  If I select a different site, then the rest of the page appears again."
    ***************************************************************************/
      this.setState({ isLoading: false});
    }, 250);
  }

  //***************************************************************************
  // called when user selects a different site
  //***************************************************************************
  handleSiteChange(siteid, siteinfo) {
    console.log("Site is changed, id: " + siteid);
    this.setState({
      selectedSite: siteid,
      thresholdsFromDb: siteinfo.alarmThresholds,
      // As database stores uvRestrictHours, we have to take a negative value to show allowed hrs
      allowDisinfectionatAllHrs: !(siteinfo.uvRestrictHours),
      securityRound_action:siteinfo.securityRound_action,
      securityRound_wait_time:siteinfo.securityRound_wait_time,
        emailsToNotify: siteinfo.emailsToNotify ? siteinfo.emailsToNotify : "",
        adminEmailsToNotify: siteinfo.adminEmailsToNotify ? siteinfo.adminEmailsToNotify : "",
        rmsName: siteinfo.rmsName ? siteinfo.rmsName : "",
        rmsUser: siteinfo.rmsUser ? siteinfo.rmsUser : "",
        rmsPw: siteinfo.rmsPw ? siteinfo.rmsPw : "",
        mapName: siteinfo.mapName ? siteinfo.mapName : "",
        siteName: siteinfo.siteName ? siteinfo.siteName : "",
        timezone: siteinfo.timezone ? siteinfo.timezone : "",
        recharge_threshold_min: siteinfo.recharge_threshold_min ? siteinfo.recharge_threshold_min : "",
        recharge_threshold_max: siteinfo.recharge_threshold_max ? siteinfo.recharge_threshold_max : "",
        restrict_ui_page:siteinfo.restrict_ui_page ? siteinfo.restrict_ui_page : "",
        include_tags_in_round:siteinfo.include_tags_in_round ? siteinfo.include_tags_in_round : ""
    }, () => {
        localStorage.setItem('selectedSite', siteid);
        localStorage.setItem('alarmThresholds', JSON.stringify(siteinfo.alarmThresholds));
    })
  }

  //***************************************************************************
  // called when user(sysadmin) selects a different Tenant
  //***************************************************************************
  handleTenantChange(tenantid, tenantName) {

    if (!this.state.sysAdmin) return;  // Do nothing as non-sysadmin will not change tenant

    console.log(`Tenant is changed, tid: ${tenantid}, name: ${tenantName}`);
    this.setState({
      selectedTenant: tenantid,
      tenantName: tenantName ? tenantName : "",
      selectedSite: "",
      thresholdsFromDb: "",
      emailsToNotify :"",
      refreshCount: this.state.refreshCount + 1
    });
    localStorage.setItem('selectedTenant', tenantid);
  }

  //***************************************************************************
  // called when user updates site config form
  //***************************************************************************
  handleSiteConfigChange() {
    console.log("Handle Site Config Change");
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }

  //***************************************************************************
  // called when user updates uvRestrictHours 
  //***************************************************************************
  handleuvRestrictHoursChange() {
    console.log("Handle uv Restrict Hours Change");
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }
  //***************************************************************************
  // called when user updates location behavior 
  //***************************************************************************
  handleLocationBehaviorChange() {
    console.log("Handle location behavior Change");
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }

  //***************************************************************************
  // Called when user has saved new values in the Site table.
  // Ask the SelectSiteSimple component to refresh its values from
  // the database so we don't get any stale data when user switches sites.
  //***************************************************************************
  handleThresholdsSaved() {
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }

  //***************************************************************************
  // called when user updates include tags 
  //***************************************************************************
  handleIncludeTagsChange(){
    console.log("Handle include tags change");
    this.setState({ refreshCount: this.state.refreshCount + 1 });
  }
  //***************************************************************************
  // render the UI
  //***************************************************************************

  render() {
    const restrict_ui = this.state.restrict_ui_page
    const hideDisinfectionPage = (restrict_ui && (restrict_ui.hasOwnProperty("disinfection")) && restrict_ui["disinfection"] === true ) ? true : false
    const hideInspectionPage = (restrict_ui && (restrict_ui.hasOwnProperty("inspection")) && restrict_ui["inspection"] === true ) ? true : false
    const hideReceptionPage = (restrict_ui && (restrict_ui.hasOwnProperty("reception")) && restrict_ui["reception"] === true ) ? true : false
    const hideSecurityPatrolPage = (restrict_ui && (restrict_ui.hasOwnProperty("security_patrol")) && restrict_ui["security_patrol"] === true ) ? true : false

    return (
      <div>
        {/**Enhancements - update the condition so that non sysadmin will not see Tenenat selection at all
        as of now it appears for a second*/}
        {this.state.sysAdmin || !this.state.selectedTenant? 
        <SelectTenant 
          onChange={this.handleTenantChange} 
          selectedTenant={this.state.selectedTenant} 
        /> : null}
        
        {this.state.selectedTenant ? 
        <SelectSiteSimple 
          onChange={this.handleSiteChange} 
          refreshCount={this.state.refreshCount} 
          selectedTenant={this.state.selectedTenant} 
          sysAdmin={this.state.sysAdmin}
        /> : null}

        {/** Show following tabs if the select site is called from any of the round config page
            Round config pages - Site Disinfectin config, Site Inspection Config, Site Security Patrol Config or Receptionist
          */}
        {this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'roundConfig') ?
            <RobotCurrentMode 
              scheduleType={this.props.scheduleType} 
              selectedSite={this.state.selectedSite} 
              hideDisinfectionPage={hideDisinfectionPage}
              hideInspectionPage={hideInspectionPage}
              hideReceptionPage={hideReceptionPage}
              hideSecurityPatrolPage={hideSecurityPatrolPage}
            /> : null}
        
        
        { /*** Show Security Patrol Config Page Elements ***/ }
        {this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'roundConfig') && this.props.scheduleType === 'security_patrol' && !hideSecurityPatrolPage ?
          (
            <>
              <ScheduleItemTable 
                scheduleType={this.props.scheduleType} 
                selectedSite={this.state.selectedSite} 
              />
              <LocationBehavior
                securityRound_action={this.state.securityRound_action}
                securityRound_wait_time={this.state.securityRound_wait_time}
                selectedSite={this.state.selectedSite} 
                onSaved={this.handleLocationBehaviorChange}
              />
              {this.state.sysAdmin ? 
              <IncludeTags
                scheduleType={this.props.scheduleType} 
                include_tags_in_round={this.state.include_tags_in_round}
                selectedSite={this.state.selectedSite} 
                onSaved={this.handleIncludeTagsChange}
              /> : null}
            </>
         
          ) : null}

        { /*** Show Inspection Config Page Elements ***/ }
        {this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'roundConfig') && this.props.scheduleType === 'inspection' && !hideInspectionPage ? 
          (
            <>
              <ScheduleItemTable 
                scheduleType={this.props.scheduleType} 
                selectedSite={this.state.selectedSite} 
              />
              <AlarmConfigSection 
                siteid={this.state.selectedSite}
                thresholdValues={this.state.thresholdsFromDb}
                onSaved={this.handleThresholdsSaved}
              />
              {this.state.sysAdmin ? 
              <IncludeTags
                scheduleType={this.props.scheduleType} 
                include_tags_in_round={this.state.include_tags_in_round}
                selectedSite={this.state.selectedSite} 
                onSaved={this.handleIncludeTagsChange}
              /> : null}
            </>
          ) : null}


        { /*** Show Disinfection Config Page Elements ***/ }
        {this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'roundConfig') && this.props.scheduleType === 'disinfection' && !hideDisinfectionPage ? 
        (
          <>
            <ScheduleItemTable 
              scheduleType={this.props.scheduleType} selectedSite={this.state.selectedSite} 
            /> 
            <ShowDisinfectionAllowedHrs 
              scheduleType="disinfection_allowed" 
              allowDisinfectionatAllHrs={this.state.allowDisinfectionatAllHrs} 
              selectedSite={this.state.selectedSite} 
              onSaved={this.handleuvRestrictHoursChange}
            />
          </>
        ): null}


        { /*** Show Reception Config Page Elements ***/ }  
        {this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'roundConfig') && this.props.scheduleType === 'reception' && !hideReceptionPage ? <ScheduleItemTable scheduleType={this.props.scheduleType} selectedSite={this.state.selectedSite} /> : null}


        { /*** Show Reporting Page Elements ***/ } 
        {(this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'reporting')) ? 
          <ShowRounds 
            restrict_ui= {restrict_ui} 
            siteid={this.state.selectedSite}  
            sitename={this.state.siteName}
            tenantName={this.state.tenantName}
            hideDisinfectionPage={hideDisinfectionPage}
            hideInspectionPage={hideInspectionPage}
            hideSecurityPatrolPage={hideSecurityPatrolPage}
            sysAdmin={this.state.sysAdmin}
          /> : null}
        

        {/** Show following tabs if the select site is called from System Config page */}
        {console.log("SELECTSITE-emails: "+this.state.emailsToNotify+"; admin: "+this.state.adminEmailsToNotify)}
        {(this.state.selectedSite && (!this.state.isLoading) && (this.props.pageType === 'sysConfig')) ? 
            <SiteConfigSection 
                siteid={this.state.selectedSite}
                sysAdmin={this.state.sysAdmin}
                emailsToNotify={this.state.emailsToNotify}
                adminEmailsToNotify={this.state.adminEmailsToNotify}
                rmsName={this.state.rmsName}
                rmsUser={this.state.rmsUser}
                rmsPw={this.state.rmsPw}
                mapName={this.state.mapName}
                siteName={this.state.siteName}
                timezone={this.state.timezone}
                recharge_threshold_min={this.state.recharge_threshold_min}
                recharge_threshold_max={this.state.recharge_threshold_max}
                restrict_ui_page={this.state.restrict_ui_page}
                onSave={this.handleSiteConfigChange} 
            /> : null}

      </div>
    )
  }
}
export default SelectSite