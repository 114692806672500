/*******************************************************************************
  IncludeTags.js

  Author: Advait
*******************************************************************************/

import React, {Component} from 'react'
import '../index.css'
import { updateIncludeTaginRound } from '../apihelpers/graphqlhelpers'

//***************************************************************************
// This class handles include tag in round values.
// Initial values to display come from props.
// Parent can change props to update the display with new values.
// When user save the changes, notify the parent.
//***************************************************************************
class IncludeTags extends Component{
  constructor(props){
    super(props)
    this.state={
      meetingroom_inspection : false,
      meetingroom_patrol : false,
      inspection_patrol : false
    }
    this.handleSaveBehavior = this.handleSaveBehavior.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }
  
  componentWillUnmount(){
    this.setState({
      meetingroom_inspection : false,
      meetingroom_patrol : false,
      inspection_patrol : false
    })
  }

  //***************************************************************************
  // If the parent changes values in props, update UI with new values
  //***************************************************************************
  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.setStates()
    }
  }
  setStates(){
    const include_tags_in_round = this.props.include_tags_in_round
    this.setState({
      meetingroom_inspection : (include_tags_in_round && (include_tags_in_round.hasOwnProperty("meetingroom_inspection")) && include_tags_in_round["meetingroom_inspection"] === true ) ? true : false,
      meetingroom_patrol : (include_tags_in_round && (include_tags_in_round.hasOwnProperty("meetingroom_patrol")) && include_tags_in_round["meetingroom_patrol"] === true ) ? true : false,
      inspection_patrol : (include_tags_in_round && (include_tags_in_round.hasOwnProperty("inspection_patrol")) && include_tags_in_round["inspection_patrol"] === true ) ? true : false
    })
  }

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    event.persist(); 
    console.log(`name : ${name}, checked: ${checked}`);
    
    // Update state directly based on the checkbox name
    if (name === "inspectionTag") {
      this.setState({ inspection_patrol: checked});
    } else if (name === "meetingRoomTag") {
      // Update the appropriate property based on scheduleType
      if (this.props.scheduleType === "inspection") {
        this.setState({ meetingroom_inspection: checked });
      } else {
        this.setState({ meetingroom_patrol: checked });
      }
    }
  }
  //***************************************************************************
  // Update the values to the database and notify parent on save button
  //***************************************************************************

  async handleSaveBehavior(e) {
    console.log(e);
    const include_tags_in_round = {
      meetingroom_inspection: this.state.meetingroom_inspection,
      meetingroom_patrol: this.state.meetingroom_patrol,
      inspection_patrol: this.state.inspection_patrol
    };
    // Call the updated function
    await updateIncludeTaginRound(this.props.selectedSite, include_tags_in_round);
    // Notify parent that new data has been saved
    this.props.onSaved();
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************
  render(){
    let modeName = "unknown";
    let tagDisplayStype = 'table-cell'
    if (this.props.scheduleType ==="inspection"){
      modeName = "Inspection";
      tagDisplayStype = 'none'
    }else if (this.props.scheduleType ==="security_patrol"){
      modeName = "Security Patrol";
    }
    return (
      <div className="individualContainer">
        <h4>Add to {modeName}</h4>
        <table className = "includeTagTable">
          <tbody>
            <tr >
              <td  style={{ display: tagDisplayStype }}>
                <label htmlFor="inspectionTag" style={{ marginRight: '20px' }}>Inspection Locations</label>
                <input
                  id="inspectionTag"
                  name="inspectionTag"
                  type="checkbox"
                  checked={this.state.inspection_patrol}
                  onChange={this.handleCheckboxChange}
                />
              </td>
              <td>
                <label htmlFor="meetingRoomTag" style={{ marginRight: '20px' }}>Telepresence Destinations</label>
                <input
                  id="meetingRoomTag" 
                  name="meetingRoomTag"
                  type="checkbox"
                  checked={(this.props.scheduleType ==="inspection") ? this.state.meetingroom_inspection : this.state.meetingroom_patrol}
                  onChange={this.handleCheckboxChange}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <button onClick={this.handleSaveBehavior} >Save</button>
      </div>
    )
  }   
}
export default IncludeTags

