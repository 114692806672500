/*******************************************************************************
  SelectTenant.js

  Author: Advait
*******************************************************************************/

import React, { Component } from 'react'
import { getTenants } from '../apihelpers/graphqlhelpers'
import '../index.css'

class SelectTenant extends Component {
  constructor(props) {
    super(props);
    this.onChange = props.onChange;  // callback when tenant is selected
    this.state = {

      refreshCount: 0,  // increment to cause the SelectSiteSimple to refresh from database
      company: "",
      tenants: [],
      selectedTenant: "",
      numberOfTenants: "",
    }
    this.handleSelectedTenant = this.handleSelectedTenant.bind(this)
  }

  async componentDidMount() {
    this.refreshFromDB()
    console.log("this.state.selectedTenant: ", this.state.selectedTenant)
    this.setState({selectedTenant: localStorage.getItem('selectedTenant')})
  }

  componentDidUpdate(prevProps) {
    // Parent can update refreshCount to ask us to refresh our data
    // from the database.
    if (this.props.selectedTenant !== prevProps.selectedTenant) {
      this.refreshFromDB();
      console.log("this.state.selectedTenant: ", this.state.selectedTenant)
      this.setState({selectedTenant: this.props.selectedTenant})
    }
  }
  async refreshFromDB() {
    let tenants = await getTenants(false); //TODO add a way to see disabled tenants
    var count = Object.keys(tenants).length;
    this.setState({ numberOfTenants: count })

    if (count === 1) {
      this.setState({ selectedTenant: tenants[0].id })
      this.onChange(tenants[0].id, tenants[0].company);
      /**  
      * If user(non-sysadmin) directly opens a page with tenant selection using url 
      * (for ex: http://fa.ava8.net/systemconfig)
      */
      localStorage.setItem('selectedTenant', tenants[0].id);
    }else{
      const id = localStorage.getItem("selectedTenant");
      tenants.sort((a, b) => a.company.toLowerCase() > b.company.toLowerCase() ? 1 : -1)
      this.setState({ tenants: tenants });
      if (id) {
        console.log("refreshFromDB should register change: "+id)
        let tenantInfo = tenants.find((tenant) => tenant.id === id)
        this.onChange(id, tenantInfo.company)
      }
    }
  }


  handleSelectedTenant(event) {
    const tenantid = event.target.value;
    this.setState({ selectedTenant: tenantid })
    let tenantInfo = tenantid ? this.state.tenants.find((tenant) => tenant.id === tenantid) : ""
    this.onChange(tenantid, tenantInfo.company);
  }
  
  /**
   * called when the user selects a different tenant, or no site.
   */
  handleTenantChange(event) {
    const tenantid = event.target.value;
    this.setState({ selectedTenant: tenantid })
  }

  //***************************************************************************
  // render the UI
  //***************************************************************************
  render() {
    if (this.state.numberOfTenants === 1) {
      return (
        <div>
        </div>
      )

    }
    else {
      const allTenants = [].concat(this.state.tenants)
        .map((tenant, i) => {
          return <option key={i} value={tenant.id}>
            {tenant.company}
          </option>
        }
        )

      return (
        <div>
          <div className="individualContainer">
            <h4>Tenant Selection</h4>
            <br />
            <select
              value={this.state.selectedTenant}
              onChange={this.handleSelectedTenant}
            >
              <option value="">-- Please choose a Tenant --</option>
              {allTenants}
            </select>
          </div>
          <br />
        </div>
      )
    }
  }
}
export default SelectTenant
