/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSite = /* GraphQL */ `
  query GetSite($id: ID!) {
    getSite(id: $id) {
      id
      tid
      siteName
      rmsName
      rmsUser
      rmsPw
      mapName
      emailsToNotify
      adminEmailsToNotify
      alarmThresholds
      ScheduleItems {
        items {
          id
          siteId
          enabled
          type
          day
          startTime
          duration
          tid
          createdAt
          updatedAt
        }
        nextToken
      }
      uvRestrictHours
      buildingAlarmIntegrated
      buildingAlarmState
      timezone
      recharge_threshold_min
      recharge_threshold_max
      restrict_ui_page
      disabled
      securityRound_action
      securityRound_wait_time
      include_tags_in_round
      createdAt
      updatedAt
    }
  }
`;
export const listSites = /* GraphQL */ `
  query ListSites(
    $filter: ModelSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tid
        siteName
        rmsName
        rmsUser
        rmsPw
        mapName
        emailsToNotify
        adminEmailsToNotify
        alarmThresholds
        ScheduleItems {
          nextToken
        }
        uvRestrictHours
        buildingAlarmIntegrated
        buildingAlarmState
        timezone
        recharge_threshold_min
        recharge_threshold_max
        restrict_ui_page
        disabled
        securityRound_action
        securityRound_wait_time
        include_tags_in_round
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      company
      sites {
        items {
          id
          tid
          siteName
          rmsName
          rmsUser
          rmsPw
          mapName
          emailsToNotify
          adminEmailsToNotify
          alarmThresholds
          uvRestrictHours
          buildingAlarmIntegrated
          buildingAlarmState
          timezone
          recharge_threshold_min
          recharge_threshold_max
          restrict_ui_page
          disabled
          securityRound_action
          securityRound_wait_time
          include_tags_in_round
          createdAt
          updatedAt
        }
        nextToken
      }
      disabled
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        sites {
          nextToken
        }
        disabled
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScheduleItem = /* GraphQL */ `
  query GetScheduleItem($id: ID!) {
    getScheduleItem(id: $id) {
      id
      siteId
      enabled
      type
      day
      startTime
      duration
      tid
      createdAt
      updatedAt
    }
  }
`;
export const listScheduleItems = /* GraphQL */ `
  query ListScheduleItems(
    $filter: ModelScheduleItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduleItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        siteId
        enabled
        type
        day
        startTime
        duration
        tid
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
